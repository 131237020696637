<template>
  <div class="about">
    <el-image v-for="(item, index) in urls" :key="index" style="width: 100%; display: flex" :src="require('@/assets/notice/'+item)" />
  </div>
</template>
<script>
import notice from '@/api/notice'
export default {
  data() {
    return {
      urls: []
    }
  },
  mounted() {
    this.getUrls()
    // console.log(this.urls)
  },
  methods: {
    getUrls() {
      const name = this.$route.params['name']
      for (let i = 0; i < notice.length; i++) {
        const element = notice[i]
        if (element.name === name) {
          this.urls = element.urls
          break
        }
      }
    }
  }
}
</script>
